$color:#00CCFF;
$white:#ffffff;
$black:#000000;
$grey:#cccccc;

@mixin trans{
  -webkit-transition:all .3s ease-in-out;
-moz-transition:all .3s ease-in-out;
-ms-transition:all .3s ease-in-out;
-o-transition:all .3s ease-in-out;
transition:all .3s ease-in-out  
}
@mixin trans1{
  -webkit-transition:all .5s ease-in-out;
  -moz-transition:all .5s ease-in-out;
  -ms-transition:all .5s ease-in-out;
  -o-transition:all .5s ease-in-out;
  transition:all .5s ease-in-out 
}
a,a:focus,button,button:focus{outline:0!important}.bg-layer,.table-cell{position:absolute;top:0;left:0}.bg-layer,.table-cell,.table-cell-center,body,html,iframe{height:100%;width:100%}body{font-size:16px;overflow-x:hidden!important}*{margin:0;padding:0;image-rendering:-o-crisp-edges;image-rendering:-webkit-optimize-contrast;-ms-interpolation-mode:nearest-neighbor}body,html,main{-webkit-font-smoothing:antialiased}body{overflow-x:hidden!important;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}img,picture,svg{max-width:100%}.bg-layer{opacity:1;background-size:cover;background-position:center center}.table-cell{margin:0;display:table}.table-cell-center{display:table-cell;vertical-align:middle}.full-div{display:inline-block;width:100%}body{font-family: 'Roboto';font-weight:400;}.half-div{display:inline-block;width:50%;float:left;}.pd-r-0{padding-right:0;}a{display:inline-block;}.pd-l-0{padding-left:0;}.txt-right,.txt-rght{text-align:right;}.half-div{display:inline-block;width:50%;}.txt-center,.txt-centr{text-align:center;}.txt-left{text-align:left;}.flex-div{display: flex;justify-content: space-between;}
body,html{min-height: 100%;height: auto;}body{background-color: white;}img{max-width: 100%;}

img{max-width: 100%;}

/* Font Family */
@font-face {
  font-family: 'Segoe UI';
  src: url('../src/assets/fonts/SegoeUI-Bold.woff2') format('woff2'),
      url('../src/assets/fonts/SegoeUI-Bold.woff') format('woff');
  font-weight: bold;font-style: normal;
}
@font-face {
  font-family: 'Segoe UI';
  src: url('../src/assets/fonts/SegoeUI.woff2') format('woff2'),
      url('../src/assets/fonts/SegoeUI.woff') format('woff');
  font-weight: normal;font-style: normal;
}
body{font-family: 'Montserrat', sans-serif;background-color: #000248;}
/* ==== Button ==== */
.reg-btn.blue,
.nav-btn-list li a{
  display: inline-block;color: $white;
  padding: 7px 20px 7px;border-radius: 25px;position: relative;
  display: inline-block;vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-weight: bold;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;

}
.reg-btn.blue::before,
.nav-btn-list li a::before{
  content: "";
  position: absolute;z-index: -1;top: 0;bottom: 0;left: 0;
  border-radius: 25px;right: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;  background: rgb(54,174,94); 
  background: -moz-linear-gradient(-45deg,  rgba(54,174,94,1) 0%, rgba(0,60,128,1) 100%);
  background: -webkit-linear-gradient(-45deg,  rgba(54,174,94,1) 0%,rgba(0,60,128,1) 100%); 
  background: linear-gradient(135deg,  rgba(54,174,94,1) 0%,rgba(0,60,128,1) 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36ae5e', endColorstr='#003c80',GradientType=1 ); 
}
.reg-btn.blue.full{padding: 7px 35px 7px;}
.reg-btn.blue:hover,.reg-btn.blue:focus,.reg-btn.blue:active,
.nav-btn-list li a:hover, .nav-btn-list li a:focus,.nav-btn-list li a:active {
  color: $white;
}
.reg-btn.full:hover:before,.reg-btn.full:focus:before,.reg-btn.full::before,
.reg-btn.blue:hover:before,.reg-btn.blue:focus:before,.reg-btn.blue:active:before,
.nav-btn-list li a:hover:before,.nav-btn-list li a:focus:before, .nav-btn-list li a:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}  
/* ==== Button ==== */


a,input,button,.sound-contianer .flex-li li,.music-post .img-pnl,.music-post,
.search-pnl button{
  text-decoration:none!important;
  @include trans();
}
/* Color */
.forget-password:hover,.like-list li a:hover,
.music-post .txt .sound-name-list li a:hover{
    color: $color;
}
.music-post:hover .img-pnl{box-shadow: 0 0px 20px rgba(0,204,205,0.5);}

    /* ====  INPUT ==== */
select{
  // background-image: url("../src/assets/img/select-bg.png");
  padding-right: 20px;font-size: 14px;
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.search-pnl input::-webkit-input-placeholder{color: #333333;font-size: 14px;}
.search-pnl input:-ms-input-placeholder {color: #333333;font-size: 14px;}
.search-pnl input::placeholder {color: #333333;font-size: 14px;}
.search-pnl input:focus{outline: none !important;box-shadow:none !important;border-color: #459EF7;box-shadow: none;}

/* Navigation */
#main-navigation{
  position: absolute;left: 0;top: 0;right: 0;padding-top: 30px;z-index: 99;
  .logo{display: inline-block;width: 110px;}
    .nav-btn-list{display: inline-block;
    li{display: inline-block;margin-left: 15px;margin-top:30px;float:left;
      &.login-item{
        .search-pnl{width: 270px;box-shadow: rgba(0,0,10px,0.3);}
      .dropdown {
          button{background-color: transparent;padding:13px 15px 0px;
              color:#535353;border:none;box-shadow:none !important;color:#535353;
              &:hover{background-color: rgba(83,83,83,1);color:white;}
            .profile-picture{
              display: inline-block;width: 40px;height: 40px;border-radius: 50%;display:inline-block;float:left;margin-right:15px;position:relative;top:-7px;
              img{width: 40px;height: 40px;border-radius: 50%;}
            }
          }
          &.show button{background-color: rgba(83,83,83,1);color:white;}
        }
        .dropdown-menu{background-color: #DBDBDB;padding: 0;
          a.dropdown-item{
            border-radius: 0;background-color: #DBDBDB;color:#535353;width:100%;min-width:175px;
            display: flex;justify-content:space-between;padding-left:10px;padding-right:10px;
            &:hover{color: white;background-color: $color;}
            &::before{display: none;}
          }
        }
      }
    }
  }
  @media (max-width: 767px){
    padding-top: 15px;
    .nav-btn-list li {margin-top: 20px;
      a{font-size: 14px;padding:7px 10px 7px;}
    }
    .logo {width: 80px;}
  }
  @media (max-width: 575px){
    padding-top: 15px;
    .nav-btn-list li {margin-top: 5px;margin-left:10px;
      a{font-size: 13px;padding:7px 5px 7px;}
    }
    .logo {width: 60px;}
  }
}
/* Navigation */
/* Header */
#landing-header{
    width: 100%;height: calc(100vh - 65px);background-repeat: no-repeat;position: relative;
    background-size: cover;background-position: center bottom;min-height: 600px;
    display: flex;justify-content: center;align-items: center;
    h1{font-size:45px;font-weight: 600;color:white;margin-bottom: 30px;margin-top: 60px;}
    p{font-size:18px;font-weight: 400;color:white;margin-bottom: 40px;}
    img{margin-top: 60px;}

    @media (max-width: 1200px){
      h1{font-size: 30px;margin-bottom: 20px;}
      p{margin-bottom: 15px;}
    }
    @media (max-width: 991px){
      min-height: 500px;
      h1{font-size: 26px;margin-bottom: 20px;}
      p{margin-bottom: 15px;font-size: 16px;}
    }
    @media (max-width: 767px){
      min-height: unset;text-align:center;
      height: auto;padding-top:80px;
    }
    @media (max-width: 575px){
      min-height: 100vh;
      h1{font-size: 22px;}
      p{font-size: 14px;}
    }
}

/* Header */
.content{padding-top: 50px;padding-bottom: 50px;text-align: center;position: relative;z-index: 1;background-color: $white;
  h1{font-size: 40px;color: black;font-weight: normal;margin-bottom: 50px;}
}
footer{
  background-color: #000248;text-align: center;padding-top: 20px;padding-bottom: 20px;
  p{font-size: 16px;margin-bottom: 0;color: white;font-weight: 600;}
}
button.close{
  position: absolute;right: 15px;top: 15px;z-index: 2;color: white;opacity: 1;font-size: 30px;
}
.modal{background-color: #000248;
  .modal-content{background-color: rgba(0,60,128,0.3);border-radius:20px;
    .modal-header{text-align: center;display: inline-block;border:none;padding-top: 50px;}
    .modal-body{padding-bottom: 40px;}
    h5{color: white;font-weight: bold;}
    .forgt-sign-list{display: inline-block;margin-bottom:30px;
      li{display: inline-block;position: relative;
        &:not(:first-child):before{position: absolute;left: 10px;top: 3px;content: '';background-color: white;width: 1px;height: 18px;}
        a,button
        {display: inline-block;background-color: transparent;border:none;color: white;font-size: 16px;font-weight: 500;margin-left:20px;
          &:hover{color: #DCBA0F;}
        }
      }
    }
  }
}
@media (min-width: 576px){
  .modal-dialog {max-width: 600px;width: 95%;}
}
input.form-control{
  background-color: transparent;
  border:none;border-radius: 0;margin-bottom: 20px;
  border-bottom: 1px solid #ffffff;
}
input.form-control.email{
  background-image: url(assets/img/email.png);background-size: 15px;
  background-repeat: no-repeat;background-position: 99% center;
}
input.form-control.password{
  background-image: url(assets/img/eye-icon.png);background-size: 15px;
  background-repeat: no-repeat;background-position: 99% center;
}
input.form-control.copy{
  background-image: url(assets/img/Icon-copy.png);background-size: 15px;
  background-repeat: no-repeat;background-position: 99% center;
}

.trading-bot{padding-top: 200px;}
.iinjaz-bot-form{
    box-shadow: 0px 3px 6px rgba(255, 255, 255, 0.16);border-radius:15px;
  .iinjaz-bot-head{
    padding: 30px 50px;border-radius: 15px 15px 0 0;
    background: rgb(54,174,94); 
    background: -moz-linear-gradient(-45deg,  rgba(54,174,94,1) 0%, rgba(0,60,128,1) 100%); 
    background: -webkit-linear-gradient(-45deg,  rgba(54,174,94,1) 0%,rgba(0,60,128,1) 100%); 
    background: linear-gradient(135deg,  rgba(54,174,94,1) 0%,rgba(0,60,128,1) 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36ae5e', endColorstr='#003c80',GradientType=1 ); 
    h1{font-size: 30px;font-weight: bold;color: white;margin-bottom: 0;}
  }
  .iinjaz-bot-body{
    padding: 30px 50px; border-radius:0 0  15px 15px;
    background-color: white;
    select.form-control,
    input.form-control{border-color: black;background-color: #DFDFDF;border-radius: 5px 5px 0 0;margin-bottom: 20px;}
    label{color: #003C80;font-weight: 600;font-size: 18px;}
  }
}
.marg-bot{margin-bottom: 10px;}
.form-check-inline{min-width: 20%;
  label{color:rgba(0,0,0,0.6)}
}
.dashboard-landing-pnl{
  padding-top: 200px;text-align: center;
  h1{color: white;font-size: 40px;font-weight: 600;text-transform: uppercase;}
  p{color: white;}
  .img-pnl{width: 40%;padding-left:30px;
    img{width: 100%;}
  }
  table{width: 100%;color: white;background-color:white;border-radius:5px;margin-top:30px;max-height:500px;overflow-y:scroll;
    caption{caption-side:top;text-align: center;background-color: #ffffff;font-weight: bold;color: #ffffff;font-size: 40px;
      background: rgb(54,174,94); 
      background: -moz-linear-gradient(-45deg,  rgba(54,174,94,1) 0%, rgba(0,60,128,1) 100%); 
      background: -webkit-linear-gradient(-45deg,  rgba(54,174,94,1) 0%,rgba(0,60,128,1) 100%); 
      background: linear-gradient(135deg,  rgba(54,174,94,1) 0%,rgba(0,60,128,1) 100%); 
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36ae5e', endColorstr='#003c80',GradientType=1 ); 
    }
    thead{width: 100%;color: #fff;border-radius:5px;font-weight: 600;position: sticky;top: 0;position: sticky;left: 0;top: 0;
      background: rgb(54,174,94); 
      background: -moz-linear-gradient(-45deg,  rgba(54,174,94,1) 0%, rgba(0,60,128,1) 100%); 
      background: -webkit-linear-gradient(-45deg,  rgba(54,174,94,1) 0%,rgba(0,60,128,1) 100%); 
      background: linear-gradient(135deg,  rgba(54,174,94,1) 0%,rgba(0,60,128,1) 100%); 
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36ae5e', endColorstr='#003c80',GradientType=1 ); 
      td{padding: 10px 0;}
    }
    tbody{width: 100%;color: black;
      td{padding: 10px 0;font-size: 14px;}
    }
  }

  @media (max-width: 1200px){
    h1{font-size: 36px;}
  }
  @media (max-width: 991px){
    padding-top: 180px;
    h1{font-size: 30px;}
    .run-btns-pnl{display: inline-block;width: 100%;padding-top: 50px;padding-bottom: 0;}
    .dashboard-list{width: 100%;}
    .img-pnl{width: 100%;text-align: center;
      img{width: auto;margin: 50px auto;}
    }
  }
  @media (max-width: 575px){
    h1{font-size: 26px;}
    .dashboard-list{display: inline-block;width: 100%;
      li{width: 100%;max-width: unset;margin-bottom: 20px;max-width: 200px;}
    }
    .img-pnl{width: 100%;padding-left: 0;}
  }
}
.run-btns-pnl{padding-top: 100px;padding-bottom: 50px;}
.dashboard-list{
  width: 100%;display: flex;justify-content: space-around;
  li{display: inline-block;width:30%;border-radius: 15px;padding: 20px;max-width:210px; background-color: white;
    a.reg-btn{width: 100%;margin-bottom: 50px;text-transform: uppercase;font-weight: 600;padding:7px 10px !important;}
    &.volume-list{display: flex;justify-content:center;align-items:center;padding:20px;
      p{font-size: 14px;color: #fff;border-radius: 5px;padding: 7px 0; 
        background: linear-gradient(135deg, #36ae5e 0%, #003c80 100%);
        span{font-weight: bold;font-size: 16px;display: inline-block;width: 100%;}
      }
    }
    button{
      width: 110px;height: 110px;max-width: 100%;border-radius: 50%;box-shadow: 0 0px 10px rgba(112,112,112,0.6);
      background-color: #36ae5e;border:10px solid white;
      i{font-size: 28px;color: white;display: inline-block;border:2px solid white;border-radius: 50%;padding:15px;
      }
      &.blue{background-color: #003c80;}
    }
    // &:not(:first-child){background-color: white;}
  }
}

@media (max-width: 767px){
  .table-contnr{max-width: 100%;overflow-x: scroll;
    table{width: 800px;
      caption{text-align: left;padding-left: 20px;}
    }
  }
  .trading-bot{padding-top: 150px;}
  .iinjaz-bot-form .iinjaz-bot-body,
  .iinjaz-bot-form .iinjaz-bot-head{padding: 20px 20px;}
}
@media (max-width: 575px){
  .iinjaz-bot-form .iinjaz-bot-head h1{font-size: 24px;}
  .iinjaz-bot-form .iinjaz-bot-body label{font-size: 14px;}
}
.custom-vals-label{
  text-align: center;
  margin-bottom: 0px;

}

.custom-vals{
  text-align: center;
  margin-bottom: 30px;
}