.Text-white{
    overflow-x: unset !important;

}

.stickyFooter{
    min-height: calc(100vh - 64px);
}

/* input.form-control.email {
    color: white !important;
  
}
input.form-control.password {
    color: white !important;
  
} */